<template>
  <div>
    <ul class="nav w-100vw">
      <li v-for="(item,index) in navData" :key="index"><span :class="{'on':active==item.id}" @click="scrollTo(item.id)">{{item.name}}</span></li>
    </ul>
    <div class="f1 w-100vw itemA">
      <div class="title" data-aos="slide-up">专业商品供应</div>
      <div class="f1Text" data-aos="slide-up">
        近10年商品供应链整合经验，打造匹配不同业务场景的营销商品及供应链服务体系，<br>包括实物商品、虚拟商品两大类。
      </div>
      <div class="f1-1" data-aos="slide-up">
        <img class="f1-1" src="../../assets/ProductsServices/f1-1.png" alt="">
        <ul>
          <li>品类丰富</li>
          <li>品牌授权</li>
          <li>价格优势</li>
          <li>标准输出</li>
          <li>售后支持</li>
          <li>用户体验</li>
        </ul>
        <div class="f1-panel">
          <div class="panelTitle" data-aos="slide-up">虚拟商品资源</div>
          <div class="panelText" data-aos="slide-up">1000+知名品牌人气产品，将礼券、充值一站打通</div>
          <div class="f1-panel-list1" data-aos="slide-up">
            <img src="../../assets/ProductsServices/f1-panel-list1-1.png" alt="">
            <ul>
              <li>线上充值及消费卡券</li>
              <li>O2O兑换礼券</li>
              <li>实物商品提货券</li>
              <li>支付券</li>
            </ul>
          </div>
          <div class="f1-panel-list2">
            <div class="cellList cellList1" data-aos="slide-up">
              <ul>
                <li>餐饮</li>
                <li>酒店</li>
                <li>出行</li>
                <li>旅游</li>
                <li>娱乐</li>
              </ul>
            </div>
            <div class="cellList cellList2" data-aos="slide-up">
              <ul>
                <li>购物</li>
                <li>文化</li>
                <li>健身</li>
                <li>医疗</li>
                <li>教育</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="f1-panel f1-panel2">
          <div class="panelTitle" data-aos="slide-up">实物商品资源</div>
          <div class="panelText" data-aos="slide-up">积累了强大的供应链及产品数据，满足企业各种采购场景的商品需求。</div>
          <div class="f1-panel-list1" data-aos="slide-up">
            <img src="../../assets/ProductsServices/f1-panel-list2-1.png" alt="">
            <ul>
              <li>实物商品资源</li>
              <li>实物集采</li>
              <li>一件代发</li>
              <li>提货券</li>
              <li>购物商城</li>
            </ul>
          </div>
          <div class="f1-panel-list2">
            <div class="cellList cellList3" data-aos="slide-up">
              <ul>
                <li>粮油调味</li>
                <li>水饮茗茶</li>
                <li>个护清洁</li>
                <li>汽摩生活</li>
                <li>宠物用品</li>
                <li>母婴玩具</li>
              </ul>
            </div>
            <div class="cellList cellList4" data-aos="slide-up">
              <ul>
                <li>电脑办公</li>
                <li>家居用品</li>
                <li>手机数码</li>
                <li>美妆护肤</li>
                <li>运动户外</li>
                <li>休闲零食</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="f1-2 w-100vw">
        <div class="f1-2-title">产品优势</div>
        <div class="f1-2-common w-100vw">
          <div class="w-1400px">
            <div class="text" data-aos="fade-right">
              <img class="icon" src="../../assets/ProductsServices/f1-2-icon1.png" alt="">
              <div class="content point">
                <h3>完善配套系统，多种权益发放模式</h3>
                <p>线上线下发放</p>
                <p>账号直充</p>
                <p>API提领</p>
                <p>跨平台使用</p>
                <p>支付绑定</p>
              </div>
            </div>
            <img class="mainPic" data-aos="fade-left" src="../../assets/ProductsServices/f1-2-1.png" alt="">
          </div>
        </div>
        <div class="f1-2-common w-100vw f1-2-commonBg">
          <div class="w-1400px">
            <img class="mainPic" data-aos="fade-right" src="../../assets/ProductsServices/f1-2-2.png" alt="">
            <div class="text" data-aos="fade-left">
              <img class="icon" src="../../assets/ProductsServices/f1-2-icon2.png" alt="">
              <div class="content point">
                <h3>实现全场景触达，快速激活用户</h3>
                <p>快速嵌入APP</p>
                <p>微信服务号</p>
                <p>小程序</p>
                <p>高效启动用户触达</p>
              </div>
            </div>
          </div>
        </div>
        <div class="f1-2-common w-100vw">
          <div class="w-1400px">            
            <div class="text" data-aos="fade-right">
              <img class="icon" src="../../assets/ProductsServices/f1-2-icon3.png" alt="">
              <div class="content point">
                <h3>支持目标导向设计，用户运营更有效</h3>
                <p>拉新：指定用户类型</p>
                <p>活跃：指定落地场景</p>
                <p>转化：设置领取条件</p>
              </div>
            </div>
            <img class="mainPic" data-aos="fade-left" src="../../assets/ProductsServices/f1-2-3.png" alt="">
          </div>
        </div>
        <div class="f1-2-common w-100vw f1-2-commonBg">
          <div class="w-1400px">
            <img class="mainPic" data-aos="fade-right" src="../../assets/ProductsServices/f1-2-4.png" alt="">
            <div class="text" data-aos="fade-left">
              <img class="icon" src="../../assets/ProductsServices/f1-2-icon4.png" alt="">
              <div class="content">
                <h3>丰富支付方式，玩法更丰富</h3>
                <p>支持全额、积分、混合、分期等</p>
                <p>多种支付方式及多种支付通道。</p>
              </div>
            </div>
          </div>
        </div>
        <div class="f1-2-common w-100vw">
          <div class="w-1400px">            
            <div class="text" data-aos="fade-right">
              <img class="icon" src="../../assets/ProductsServices/f1-2-icon5.png" alt="">
              <div class="content">
                <h3>支持频道专区搭建，权益使用完整闭环</h3>
                <p>打造自有营销阵地，持续营销，有效留<br>存用户。</p>
              </div>
            </div>
            <img class="mainPic" data-aos="fade-left" src="../../assets/ProductsServices/f1-2-5.png" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="f2 w-100vw itemB">
      <div class="title" data-aos="slide-up">策划营销服务</div>
      <div class="subTitle" data-aos="slide-up">
        近10年营销策划服务，为多家全国性大型银行总行、分行策划执行营销活动，<br>在用户运营、拉新促活方面积累了丰富经验。
      </div>
      <div class="f2-1" data-aos="slide-up">
        <img src="../../assets/ProductsServices/f2-1.png" alt="">
        <ul>
          <li>海量模板库</li>
          <li>定制化调整</li>
          <li>多环境嵌套</li>
          <li>丰富配置</li>
          <li>数据跟踪</li>
        </ul>
      </div>
      <div class="f2-2">
        <div class="f2-2-title" data-aos="slide-up">根据节庆热点进行全案营销策划</div>
        <div class="f2-2-text" data-aos="slide-up">
          以传统及热点多维度整合节日节点，全面覆盖营销
        </div>
        <img data-aos="slide-up" src="../../assets/ProductsServices/f2-2.png" alt="">
      </div>
      <div class="f2-3 w-100vw">
        <div class="f2-3-title" data-aos="slide-up">根据企业属性进行全案营销策划</div>
        <ul>
          <li>
            <img class="picL" data-aos="fade-right" src="../../assets/ProductsServices/f3-1-l.png" alt="">
            <img class="picR" data-aos="fade-left" src="../../assets/ProductsServices/f3-1-r.png" alt="">
          </li>
          <li>
            <img class="picL" data-aos="fade-right" src="../../assets/ProductsServices/f3-2-l.png" alt="">
            <img class="picR" data-aos="fade-left" src="../../assets/ProductsServices/f3-2-r.png" alt="">
          </li>
          <li>
            <img class="picL" data-aos="fade-right" src="../../assets/ProductsServices/f3-3-l.png" alt="">
            <img class="picR" data-aos="fade-left" src="../../assets/ProductsServices/f3-3-r.png" alt="">
          </li>
        </ul>
      </div>
    </div>
    <div class="f3 w-100vw itemC">
      <div class="title" data-aos="slide-up">数字场景开发</div>
      <div class="subTitle" data-aos="slide-up">
        在多年的金融行业服务中，拥有丰富的技术经验，具备成熟的技术开发能力，以科技驱动为核心战略，从实<br>
        际营销场景出发，帮助企业打造全场景、工具化、超轻量的技术产品工具、场景与平台。
      </div>
      <div class="f3-1" data-aos="slide-up">
        <img src="../../assets/ProductsServices/f3-1.png" alt="">
        <ul>
          <li>权益商城</li>
          <li>高频场景营销</li>
          <li>积分商城</li>
          <li>分期商城</li>
          <li>积分+现金支付平台</li>
        </ul>
      </div>
      <div class="f3-2">      
        <div class="f3-common">
          <h3 data-aos="slide-up">自定义发码核销系统</h3>
          <p data-aos="slide-up">提供便捷、高效的发码工具及核销平台，实现线上自主发券，线上或线下核销。</p>
          <p data-aos="slide-up">同时提供多各类不同使用规则的券码产品，配合指定统一交付标准，保证商品正常分发。</p>
        </div>
        <img data-aos="slide-up" src="../../assets/ProductsServices/f3-2.png" alt="">
        <div class="f3-common">
          <h3 data-aos="slide-up">高频权益场景植入</h3>
          <p data-aos="slide-up">围绕用户需求，打造高黏性场景服务，帮助金融企业完善场景生态，实现营销与需求的无缝连接，</p>
          <p data-aos="slide-up">超过20种可选高频场景，覆盖广泛客群，灵活组合，满足各种属性客户群。</p>
        </div>
        <img data-aos="slide-up" src="../../assets/ProductsServices/f3-3.png" alt="">
        <span data-aos="slide-up">*话费油卡、生活缴费、下午茶、游戏付费、影音娱乐、车主生活、外卖、旅游酒店、知识付费、超市便利等场景，场景不断丰富中。</span>
        <div class="f3-common">
          <h3 data-aos="slide-up">场景化SaaS权益商城</h3>
          <p data-aos="slide-up">通过打造权益平台，实现与客户消费场景的对接，并可完成资金的清算管理，</p>
          <p data-aos="slide-up">实现安全可控、集中高效、统一管理、统一清算、实时监控的电子商城平台。</p>
        </div>
        <img data-aos="slide-up" src="../../assets/ProductsServices/f3-4.png" alt="">
      </div>
      <div class="f3-3 w-100vw">
        <h3 data-aos="slide-up">产品优势</h3>
        <img data-aos="slide-up" class="f3-3-1" src="../../assets/ProductsServices/f3-5.png" alt="">
        <img data-aos="slide-up" class="f3-3-2" src="../../assets/ProductsServices/f3-6.png" alt="">
      </div>
    </div>

     <div class="f4 w-100vw itemD">
      <div class="title" data-aos="slide-up">平台流量运营</div>
      <div class="subTitle" data-aos="slide-up">
        拥有平台搭建及运营服务经验，以整合营销为手段助力企业实现用户拉新、促活、转化、留存，<br>助力品牌营销、用户精准运营以及流量资产化。
      </div>
      <div class="f4-1 f4-common">
        <img class="picL" data-aos="fade-right" src="../../assets/ProductsServices/f4-1-1.png" alt="">
        <img class="picR" data-aos="fade-left" src="../../assets/ProductsServices/f4-1-2.png" alt="">
      </div>
      <div class="f4-2 f4-common bg">
        <img class="picL" data-aos="fade-right" src="../../assets/ProductsServices/f4-2-1.png" alt="">
        <img class="picR" data-aos="fade-left" src="../../assets/ProductsServices/f4-2-2.png" alt="">
      </div>
      <div class="f4-3 f4-common">
        <img class="picL" data-aos="fade-right" src="../../assets/ProductsServices/f4-3-1.png" alt="">
        <img class="picR" data-aos="fade-left" src="../../assets/ProductsServices/f4-3-2.png" alt="">
      </div>
    </div>
    <svg t="1627886719995" v-show="showBackToTop" class="icon backToTop" @click="backToTop" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2757" width="200" height="200"><path d="M4.621 514.463h98.188v327.301h98.188v-327.301h98.188v-65.449h-294.563z" p-id="2758" fill="#ae1e26"></path><path d="M528.209 154.51l-163.446 196.347 327.273-0.219z" p-id="2759" fill="#ae1e26"></path><path d="M331.907 841.765h327.286v-392.75h-327.286v392.75zM430.095 514.463h130.912v261.823h-130.912v-261.823z" p-id="2760" fill="#ae1e26"></path><path d="M691.933 449.015v392.75h98.188v-163.651h229.1v-229.1h-327.286zM921.033 612.652h-130.912v-98.188h130.912v98.188z" p-id="2761" fill="#ae1e26"></path></svg>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        navData:[
          {id:4,name:"权益商品供应"},
          {id:1,name:"策划营销服务"},
          {id:2,name:"数字场景开发"},
          {id:3,name:"平台流量运营"},
        ],
        active: 0, // 当前激活的导航索引
        scrollTop:'',//滚动距离
        offsetTop:[
          {itemA:""},
          {itemB:""},
          {itemC:""},
          {itemD:""}
        ],
        showBackToTop:false,
      }
    },
    mounted () {
       this.$nextTick(function () {
        window.addEventListener('scroll', this.handleScroll)
        this.offsetTop.itemA = document.querySelector('.itemA').offsetTop
        this.offsetTop.itemB = document.querySelector('.itemB').offsetTop
        this.offsetTop.itemC = document.querySelector('.itemC').offsetTop
        this.offsetTop.itemD = document.querySelector('.itemD').offsetTop
      })

      const linkTo = this.$route.params.to;
      if(linkTo != undefined && linkTo){
        this.scrollTo(linkTo)
      }
    },
    computed:{
    	ProductsServicesTo(){
      		return this.$store.state.ProductsServicesTo
    	}
  	},
    watch: {
      ProductsServicesTo(newValue, oldValue) {
        this.scrollTo(newValue)
      }
    },
    destroy() {
      window.removeEventListener('scroll', this.handleScroll)      
    },
    methods: {
      handleScroll () {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop//获取滚动距离
        this.scrollTop = scrollTop//data里return了一个全局的scrollTop
        // console.log(scrollTop)
        //这边距离用来判断滚轮滚动相应的距离改变导航栏对应菜单样式
        if (scrollTop > this.offsetTop.itemB-100) {
          this.active = 1
          this.showBackToTop = true
        }
        if(scrollTop > this.offsetTop.itemC-100){
          this.active = 2
        }
        if(scrollTop > this.offsetTop.itemD-100){
          this.active = 3
        }
        if(scrollTop < this.offsetTop.itemB-100){
          this.active = 4
          this.showBackToTop = false
        }
      },
      //跳转到指定元素
      scrollTo(val){
        this.active = val
        // 定义一次跳 50 个像素，数字越大跳得越快，但是会有掉帧得感觉
        const STEP = 100
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop//获取滚动距离
        var targetOffsetTop = ''
        if(val == 1){
          targetOffsetTop = document.querySelector('.itemB').offsetTop - 60//获取指定元素视口距离
        }
        if(val == 2){
          targetOffsetTop = document.querySelector('.itemC').offsetTop - 60//获取指定元素视口距离
        }
        if(val == 3){
          targetOffsetTop = document.querySelector('.itemD').offsetTop - 60//获取指定元素视口距离
        }
        if(val == 4){
          targetOffsetTop = document.querySelector('.itemA').offsetTop - 60//获取指定元素视口距离
        }
        if (scrollTop < targetOffsetTop) {
          // 往下滑
          smoothDown()
          // console.log(targetOffsetTop,'视口距离')
        }else{
          // console.log(targetOffsetTop,'targetOffsetTop')
          smoothUp()
        }
        // 定义下滑函数
        function smoothDown() {
          // console.log(scrollTop,'scrollTop')
          // 如果当前 scrollTop 小于 targetOffsetTop 说明视口还没滑到指定位置
          if (scrollTop < targetOffsetTop) {
            // 如果和目标相差距离大于等于 STEP 就跳加上 STEP
            // 否则直接跳到目标点，目标是为了防止跳过了。
            if (targetOffsetTop - scrollTop >= STEP) {
              scrollTop += STEP
            } else {
              scrollTop = targetOffsetTop
            }
            document.body.scrollTop = scrollTop
            document.documentElement.scrollTop = scrollTop
            // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
            // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
            requestAnimationFrame(smoothDown)
          }
        }
        // 定义上滑函数
        function smoothUp() {
          // 如果当前 scrollTop 大于 targetOffsetTop 说明视口已经滑过指定位置需要上滑
          if (scrollTop > targetOffsetTop) {
            // 如果和目标相差距离大于等于 STEP 就跳减去 STEP
            // 否则直接跳到目标点，目标是为了防止跳过了。
            if (scrollTop - targetOffsetTop >= STEP) {
              scrollTop -= STEP
            } else {
              scrollTop = targetOffsetTop
            }
            document.body.scrollTop = scrollTop
            document.documentElement.scrollTop = scrollTop
            // 屏幕在绘制下一帧时会回调传给 requestAnimationFrame 的函数
            // 关于 requestAnimationFrame 可以自己查一下，在这种场景下，相比 setInterval 性价比更高
            requestAnimationFrame(smoothUp)
          }
        }        
      },
      backToTop(){
        var gotoTop= function(){
          var currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
          currentPosition -= 100;
          if (currentPosition > 0) {
            window.scrollTo(0, currentPosition);
          }
          else {
            window.scrollTo(0, 0);
            clearInterval(timer);
            timer = null;
          }
        }
        var timer=setInterval(gotoTop,1);
      }
    },
  }
</script>

<style src="./ProductsServices.scss" lang="scss" scoped></style>